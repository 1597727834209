import React from "react";
import * as moment from "moment";
import "moment-duration-format";

interface TimerProps {
  active: boolean;
  initialMillis: number;
  outOfTime: () => void;
  onTouch: () => void;
}

interface TimerState {
  milliTimeRemaining: number;
  interval: NodeJS.Timeout | undefined;
}

const TIMER_INTERVAL = 100;

export default class Timer extends React.Component<TimerProps, TimerState> {
  constructor(props: TimerProps) {
    super(props);
    this.state = {
      milliTimeRemaining: props.initialMillis,
      interval: undefined
    };
  }

  public render() {
    const momentTimeRemaining = moment.duration(this.state.milliTimeRemaining);
    const formattedTime = momentTimeRemaining.format("mm:ss", { trim: false });
    const { active } = this.props;
    return (
      <article
        className={`timer ${active ? "active" : ""}`}
        onClick={this.props.onTouch}
      >
        <time>{formattedTime}</time>
      </article>
    );
  }

  public componentWillMount() {
    const interval = setInterval(this.updateTime, TIMER_INTERVAL);
    this.setState({ interval });
  }

  public componentWillUnmount() {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  public updateTime = () => {
    const { active, outOfTime } = this.props;
    if (active) {
      const newTimeRemaining = this.state.milliTimeRemaining - TIMER_INTERVAL;
      if (newTimeRemaining <= 0) {
        outOfTime();
      } else {
        this.setState({ milliTimeRemaining: newTimeRemaining });
      }
    }
  };
}
