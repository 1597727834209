import React from "react";
import "./App.css";
import Timer from "./components/Timer";
import TimeSettings from "./components/TimeSettings";
import Controls from "./components/Controls";

interface AppState {
  round: number;
  paused: boolean;
  player1Turn: boolean;
  player2Turn: boolean;
}
const FiveMinutes = 5 * 60 * 1000;
export default class App extends React.Component<{}, AppState> {
  state: AppState = {
    paused: false,
    player1Turn: false,
    player2Turn: false,
    round: 1
  };

  public render() {
    const { player1Turn, player2Turn, paused, round } = this.state;
    return (
      <article className="app">
        <Timer
          key={-round}
          initialMillis={FiveMinutes}
          onTouch={this.onPlayer1Touch}
          outOfTime={this.outOfTime}
          active={player1Turn && !paused}
        />
        <Controls
          paused={paused}
          togglePause={this.togglePause}
          resetTimers={this.onReset}
        />
        <Timer
          key={round}
          initialMillis={FiveMinutes}
          onTouch={this.onPlayer2Touch}
          outOfTime={this.outOfTime}
          active={player2Turn && !paused}
        />
      </article>
    );
  }

  private togglePause = () => {
    this.setState({ paused: !this.state.paused });
  };

  private onPlayer1Touch = () => {
    const { paused } = this.state;
    if (!paused) {
      this.setState({ player1Turn: false, player2Turn: true });
    }
  };

  private onPlayer2Touch = () => {
    const { paused } = this.state;
    if (!paused) {
      this.setState({ player1Turn: true, player2Turn: false });
    }
  };

  private onReset = () => {
    this.setState({
      round: this.state.round + 1,
      player1Turn: false,
      player2Turn: false,
      paused: false
    });
  };

  private outOfTime = () => {
    this.setState({
      player1Turn: false,
      player2Turn: false,
      paused: false
    });
  };
}
