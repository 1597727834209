import React from "react";
import { ReactComponent as Play } from "./Play.svg";
import { ReactComponent as Pause } from "./Pause.svg";
import { ReactComponent as Reset } from "./Refresh.svg";

export interface ControlsProps {
  togglePause: () => void;
  resetTimers: () => void;
  paused: boolean;
}

export default class Controls extends React.Component<ControlsProps> {
  public render() {
    const { paused, togglePause, resetTimers } = this.props;
    return (
      <div className="controls">
        <Reset onClick={resetTimers} />
        {paused ? (
          <Play onClick={togglePause} />
        ) : (
          <Pause onClick={togglePause} />
        )}
      </div>
    );
  }
}
